import { Button, IconButton, Tooltip } from '@material-ui/core';
import * as React from 'react';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const AnnotationNode: React.FC<any> = ({ data }) => (
  <div>
    <Tooltip title={data.tooltip} arrow style={{ fontSize: '0.7rem', fontWeight: 400, lineHeight: 1, color: 'rgba(0, 0, 0, 0.54)' }}>
      <Button>
        {data.label.toUpperCase()}
        <IconButton aria-label='info' size='small' style={{ fontSize: '1rem' }}>
          <InfoOutlined fontSize='inherit' />
        </IconButton>
      </Button>
    </Tooltip>
  </div>
);

export default React.memo(AnnotationNode);
