import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SectionFieldContainerProps } from 'typings';
import AddPhotoIcon from '../../assets/Photo/icon-add-photo.svg';
import FieldTitle from './FieldTitle';

const useStyles = makeStyles(() =>
  createStyles({
    selectCard: {
      borderRadius: '4px',
      backgroundColor: '#f1f1f1',
      backgroundImage: `url(${AddPhotoIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      cursor: 'pointer'
    },
    photoContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
      gap: '8px'
    },
    mobilePhotoContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '4px'
    },
    mobileSize: {
      width: '5rem',
      height: '5rem'
    },
    desktopSize: {
      width: '8rem',
      height: '8rem'
    }
  })
);

const Photo: React.FC<SectionFieldContainerProps> = (photoProps) => {
  const classes = useStyles();
  const { isMobile, sectionFieldDetails } = photoProps;
  const { name, isMandatory } = sectionFieldDetails;
  return (
    <div>
      <FieldTitle name={name} isMandatory={isMandatory} />
      <br />
      <div className={isMobile ? classes.mobilePhotoContainer : classes.photoContainer}>
        <div className={isMobile ? `${classes.mobileSize} ${classes.selectCard}` : `${classes.desktopSize} ${classes.selectCard}`} />
        <StaticImage
          className={isMobile ? classes.mobileSize : classes.desktopSize}
          layout='constrained'
          placeholder='tracedSVG'
          src='../../assets/Photo/sample-photo.jpg'
          alt='sample photo'
        />
      </div>
    </div>
  );
};

export default Photo;
