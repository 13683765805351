import {
  Template,
  SectionField,
  SectionFieldInputTypeOld,
  SectionFieldInputType,
  SectionFieldOption,
  NovadeLiteTemplateInput
} from '../typings';
import { formatColorCode } from './common';
import { FieldInputType } from './fieldInputType';
import { setWorkflowPhase } from './workflowutils';

let templateValues: NovadeLiteTemplateInput;

export const setTemplateValues = (body: NovadeLiteTemplateInput) => {
  templateValues = body;
};

export const getTemplateValues = () => templateValues;

/**
 * @function
 * @name updateTemplateCountryCode
 * @description Add country code into template data.
 * Note: Country code is needed when call /portal-template-pdf endpoint
 */
export const updateTemplateCountryCode = (countryCode: string) => {
  const countryCodes = ['CN', 'FR', 'ID', 'JP', 'TW'];
  templateValues.countryCode = countryCodes.includes(countryCode.toUpperCase()) ? countryCode : 'EN';
};

// Update the template URL when preview popup is opened
export function updateTemplateURL(state: any, title: string, url: string) {
  if (typeof window !== 'undefined' && typeof url !== 'undefined') {
    window.history.pushState(state, title, url);
  }
}

/**
 * @function
 * @name setFieldInputType
 * @description Convert the fieldInputType to Graphql enum values
 * @returns {SectionFieldInputType}
 */
export function setFieldInputType(fieldInputType: SectionFieldInputTypeOld | SectionFieldInputType): SectionFieldInputType {
  switch (fieldInputType) {
    case 'NUMBER_INTEGER':
      return FieldInputType.NumberInteger;
    case 'DATE':
      return FieldInputType.Date;
    case 'LONGTEXT':
    case 'LONG_TEXT':
      return FieldInputType.LongText;
    case 'MULTISELECT':
    case 'MULTI_SELECT':
      return FieldInputType.MultiSelect;
    case 'SHORTTEXT':
    case 'SHORT_TEXT':
      return FieldInputType.ShortText;
    case 'TIME':
      return FieldInputType.Time;
    case 'DATEANDTIME':
    case 'DATE_AND_TIME':
      return FieldInputType.DateAndTime;
    case 'NUMBER_DECIMAL':
      return FieldInputType.NumberDecimal;
    case 'PHOTO':
      return FieldInputType.Photo;
    case 'SINGLESELECT':
    case 'SINGLE_SELECT':
      return FieldInputType.SingleSelect;
    case 'TOGGLE':
      return FieldInputType.Toggle;
    case 'SIGNATURE':
      return FieldInputType.Signature;
    case 'CHECKBOX':
      return FieldInputType.Checkbox;
    case 'LABEL':
      return FieldInputType.Label;
    case 'TABLE':
      return FieldInputType.Table;
    case 'FORMULA':
      return FieldInputType.Formula;
    default: {
      return fieldInputType;
    }
  }
}

const tableFieldType = FieldInputType.Table.toLowerCase();

/**
 * @function sortItemByRank
 * @description Sorts an array of items based on the 'rank' property of each field.
 * @param {any[]} fields - Array of items to be processed.
 * @returns {any[]} Sorted array of items.
 */
export function sortItemByRank(items: any[]): any[] {
  return items.sort((itemA, itemB) => itemA.rank - itemB.rank);
}

/**
 * @function updateFieldsValue
 * @description Update the value of corresponding properties of each field
 * - Transforms the `fieldInputType` value of each field to its corresponding appropriate value
 * - Convert value of 'textValidation' from 'NONE' to 'None'
 * - Add 'formula' in 'extra' if field is Formula (required)
 * @param {any[]} fields - Array of fields to be processed.
 * @returns {any[]} Array of fields with their `fieldInputType` values transformed.
 */
export function updateFieldsValue(fields: any[]): any[] {
  return fields.map((field) => {
    const cloneField = { ...field };
    const extra = field.extra ?? {};
    const selectOptions: Array<SectionFieldOption> = field.selectOptions ?? {};
    if (field.textValidation) extra.textValidation = field.textValidation;
    delete cloneField.textValidation;
    if (field.galleryAllowed) extra.galleryAllowed = field.galleryAllowed;
    delete cloneField.galleryAllowed;
    cloneField.fieldInputType = setFieldInputType(field.fieldInputType);
    if (cloneField.fieldInputType === FieldInputType.Formula && !extra.formula) extra.formula = '';
    if (cloneField.fieldInputType === FieldInputType.Checkbox && extra.score === null) delete extra.score;
    cloneField.selectOptions = selectOptions.map((option) => {
      const modifiedOption = { ...option };
      if (modifiedOption.score === null) delete modifiedOption.score;
      return modifiedOption;
    });
    if (field.dataMappingKeyID === null) delete cloneField.dataMappingKeyID;
    cloneField.extra = extra;
    return cloneField;
  });
}

/**
 * @function getSectionField
 * @description Gets the data of section field.
 *  - Update 'fieldInputType' in subfields
 *  - Sort the subfields by rank if it's a 'table' field, otherwise return the section field with empty subfields.
 * @param {SectionField} field - The field to process
 * @returns {SectionField} - The processed field
 */
export function getSectionField(field: SectionField): SectionField {
  const isTableField = field.fieldInputType.toLowerCase() === tableFieldType;
  const containSubFields = isTableField && field.subFields && field.subFields.length > 0;
  let subFields: any = [];
  if (containSubFields) {
    subFields = updateFieldsValue(field.subFields);
    subFields = sortItemByRank(subFields);
  }

  return { ...field, subFields };
}

/**
 * @function getSortedTemplate
 * @description Get the template data with sorted section/fields
 * @param {Template} template - The template to be processed
 * @returns {Template} - The processed template
 */
export function getSortedTemplate(template: Template): NovadeLiteTemplateInput {
  const { templateSections, workflowSteps, tags } = template;

  const templateData = templateSections.map((section) => {
    const fields = updateFieldsValue(section.sectionFields.map((sectionField) => getSectionField(sectionField)));
    return { ...section, sectionFields: sortItemByRank(fields) };
  });

  const steps = workflowSteps
    ? workflowSteps.map((step) => ({ ...step, phase: setWorkflowPhase(step.phase), color: formatColorCode(step.color) }))
    : undefined;

  const templateTags = tags ? tags.map((tag) => ({ ...tag, color: formatColorCode(tag.color) })) : undefined;

  return {
    ...template,
    templateSections: sortItemByRank(templateData),
    workflowSteps: steps,
    tags: templateTags
  };
}
