import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SectionFieldContainerProps } from '../typings';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: 'rgba(0, 0, 0, 0.6)',
      textAlign: 'left',
      fontSize: '0.85rem'
    }
  })
);

const Label: React.FC<SectionFieldContainerProps> = (labelProps) => {
  const { sectionFieldDetails } = labelProps;
  const classes = useStyles();
  return (
    <>
      <Typography variant='body1' classes={{ body1: classes.label }}>
        {sectionFieldDetails.name}
      </Typography>
    </>
  );
};

export default Label;
