import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TemplateSeoContentProps } from 'typings';
import useTemplateStyles from '../../styles/templateStyle';

const TemplateSeoContent: React.FC<TemplateSeoContentProps> = ({ isMobile, seoContent, textColor }) => {
  if (isMobile) return null;
  const classes = useTemplateStyles();
  const color = textColor || '#fff';

  return (
    <Grid item xs={12} md={12} className={classes.templateSeoSection}>
      <div className={classes.templateSeoText}>
        <Typography variant='caption' style={{ color: `${color}` }}>
          {seoContent}
        </Typography>
      </div>
    </Grid>
  );
};

export default TemplateSeoContent;
