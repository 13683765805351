import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { FieldTitleProps } from 'typings';
import useStyles from '../../styles/componentStyle';

const FieldTitle: React.FC<FieldTitleProps> = ({ name, isMandatory }) => {
  const classes = useStyles();

  return (
    <Typography variant='body1' color='primary' className={classes.fieldTitle}>
      {isMandatory ? <span className={classes.asterisk}>*</span> : null}
      {name}
    </Typography>
  );
};

export default FieldTitle;
