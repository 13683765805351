import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { TemplateSection, TemplateContainerProps, TemplateScoreContextType } from '../typings';
import TemplateSectionContainer from './TemplateSectionContainer';
import { setTemplateValues } from '../utils/templateValues';
import { TemplateScoreContext } from '../contexts/TemplateScoreContext';
import { calculateTotalScores, getScoreDisplayValues } from '../utils/previewScoreUtils';
import Score from './SectionFields/Score';
import useTemplateStyles from '../styles/templateStyle';

const DesktopFormContainer: React.FC<TemplateContainerProps> = ({ templateDetails, templateLanguage, templateMaxScoreMap }) => {
  const templateScore = React.useContext<TemplateScoreContextType>(TemplateScoreContext);
  setTemplateValues(templateDetails);
  const { name, templateSections } = templateDetails;
  const maxScores = calculateTotalScores(templateMaxScoreMap ?? {});
  const templateScoreEnabled = Object.keys(templateMaxScoreMap ?? {}).length > 0;
  const displayedScoreStr = templateScoreEnabled ? getScoreDisplayValues(templateScore.templateScoreMap, maxScores) : '';
  const classes = useTemplateStyles();

  return (
    <>
      <Typography variant='h2' className={classes.templateHeaderTwo}>
        {name}
      </Typography>
      <Score label='Total Score' scoreValue={displayedScoreStr} isMobile={false} />
      {templateSections.map((eachSection: TemplateSection) => (
        <div key={eachSection.rank}>
          <TemplateSectionContainer
            isMobile={false}
            templateSection={eachSection}
            templateLanguage={templateLanguage}
            templateMaxScoreMap={templateMaxScoreMap}
          />
        </div>
      ))}
    </>
  );
};

export default DesktopFormContainer;
