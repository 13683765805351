import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Button, Container, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LocationLanguageContextType, StrapiWorkflowDialog, WorkflowDialogProps } from 'typings';
import { Languages } from '../../utils/getAllSupportedLanguages';
import { LocationLanguageContext } from '../../contexts/LocationLanguageContext';
import useWorkflowDialogStyles from '../../styles/WorkflowDialog';
import { matchesMobile } from '../../utils/mediaQuery';

const strapiWorkflowActionQuery = graphql`
  query WorkflowDialogQuery {
    allStrapiWorkflowActionDialog {
      nodes {
        VideoURL
        Title
        NovadeLiteUrl
        NovadeLiteBtnLabel
        LearnBtnUrl
        LearnBtnLabel
        Description
        locale
      }
    }
    allStrapiWorkflowStateDialog {
      nodes {
        VideoURL
        Title
        NovadeLiteUrl
        NovadeLiteBtnLabel
        LearnBtnUrl
        LearnBtnLabel
        Description
        locale
      }
    }
  }
`;

const WorkflowDialog: React.FC<WorkflowDialogProps> = ({ open, dialogType, onDialogClose }) => {
  const data = useStaticQuery(strapiWorkflowActionQuery);
  const dialogContents: StrapiWorkflowDialog[] =
    dialogType === 'state' ? data.allStrapiWorkflowStateDialog.nodes : data.allStrapiWorkflowActionDialog.nodes;

  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const dialogContent = dialogContents.find((content) => content.locale === language);

  const handleClose = () => {
    onDialogClose();
  };

  const classes = useWorkflowDialogStyles();
  const isMobile = matchesMobile();
  const videoDimension = isMobile ? { width: '100%', height: 'auto' } : { width: '550', height: '320' };

  if (!dialogContent) return null;

  return (
    <Dialog
      maxWidth='md'
      onClose={handleClose}
      aria-label='Workflow State Dialog'
      open={open}
      PaperProps={{ classes: { root: classes.dialogPaper } }}
    >
      <div className={classes.dialogClose}>
        <IconButton aria-label='close' onClick={handleClose} className='close-icon-button'>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.workflowDialogContent}>
        <Container>
          <iframe
            width={videoDimension.width}
            height={videoDimension.height}
            src={dialogContent?.VideoURL}
            title={dialogContent?.Title}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </Container>
        <Container>
          <Typography variant='h6'>{dialogContent?.Title}</Typography>
          <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
            {dialogContent?.Description}
          </Typography>
        </Container>
        <Container>
          <Button variant='contained' href={dialogContent.NovadeLiteUrl} className={classes.nlBtn} target='_blank' rel='noreferrer'>
            {dialogContent.NovadeLiteBtnLabel}
          </Button>
        </Container>
        <Container>
          <Typography variant='body2'>
            <a href={dialogContent.LearnBtnUrl} className={classes.learnBtn} target='_blank' rel='noreferrer'>
              {dialogContent.LearnBtnLabel}
            </a>
          </Typography>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default WorkflowDialog;
