import { Buffer } from 'buffer';
import {
  NovadeLiteUser,
  StrapiAuthenticatedUser,
  StrapiEmailAttachment,
  StrapiEmailMessage,
  StrapiTemplate,
  StrapiTemplateSearchResult
} from '../typings';

const backendURL = process.env.GATSBY_API_URL;
const postData: StrapiAuthenticatedUser = {
  identifier: process.env.GATSBY_TEMPLATE_PORTAL_IDENTIFIER!,
  password: process.env.GATSBY_TEMPLATE_PORTAL_PASSWORD!
};

let emailMessage: StrapiEmailMessage;

export const setEmailValues = (
  novadeLiteUser: NovadeLiteUser,
  lang: string = 'en',
  template: StrapiTemplate | StrapiTemplateSearchResult,
  attachmentData?: StrapiEmailAttachment
) => {
  emailMessage = {
    to: novadeLiteUser?.email,
    username: novadeLiteUser?.displayName,
    locale: lang,
    template: {
      title: template.Title,
      link: typeof window !== 'undefined' ? `${window.location.host}/template/${template.Slug}` : ''
    },
    attachment: attachmentData
  };
};

export const getEmailValues = () => emailMessage;

export const getAttachmentData = (fileContent: ArrayBuffer | string, fileName: string, fileType: string) => {
  const filetypes = ['application/pdf'];
  if (!fileContent || !fileName || !fileType || !filetypes.includes(fileType)) return null;

  let content = '';
  if (typeof fileContent === 'string') {
    content = Buffer.from(fileContent).toString('base64');
  } else {
    // to avoid typescript error
    content = Buffer.from(fileContent).toString('base64');
  }
  const attachmentData: StrapiEmailAttachment = {
    content,
    filename: fileName,
    type: fileType,
    disposition: 'attachment'
  };
  return attachmentData;
};

async function callStrapiAPI(endpoint: string, token: string, data: StrapiAuthenticatedUser | StrapiEmailMessage) {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token.length !== 0 ? `Bearer ${token}` : ''
    },
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

export async function sendEmail(emailMsg: StrapiEmailMessage) {
  const emailEndpoint = `${backendURL}/send-email`;
  const loginURL = `${backendURL}/auth/local`;

  if (!emailMsg.to || !emailMsg.template) return;

  await callStrapiAPI(loginURL, '', postData)
    .then((data) => {
      if (data.jwt) {
        return callStrapiAPI(emailEndpoint, data.jwt, emailMsg);
      }
      throw new Error(`JWT not found`);
    })
    .then((res) => res)
    .catch((e) => console.log('error', e));
}
