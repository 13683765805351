import { createStyles, makeStyles } from '@material-ui/core/styles';
import { globalColors } from './globalColorVariables';

const useToggleStyles = makeStyles(() =>
  createStyles({
    toggleButton: {
      marginTop: '0.5rem !important'
    },
    toggleContainer: {
      display: 'grid',
      gap: '8px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(calc(93% / 3), 1fr))'
    },
    selectedToggleButton: {
      backgroundColor: `${globalColors.primaryColor} !important`,
      color: 'white !important'
    }
  })
);

export default useToggleStyles;
