import { createStyles, makeStyles } from '@material-ui/core/styles';

const useScoreStyles = makeStyles(() =>
  createStyles({
    scoreText: {
      margin: '1rem 0'
    },
    textCenter: {
      textAlign: 'center'
    },
    scoreTag: {
      background: '#D5E0EE',
      borderRadius: '16px',
      padding: '6px 8px',
      width: '100%',
      height: '3rem'
    }
  })
);

export default useScoreStyles;
