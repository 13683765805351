import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import { useTheme, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SectionFieldContainerProps, SectionFieldOption, TemplateScoreContextType } from '../../typings';
import useStyles from '../../styles/componentStyle';
import { TemplateScoreContext } from '../../contexts/TemplateScoreContext';

function getStyles(currentValue: number, multiValueArr: number[], theme: Theme) {
  if (multiValueArr.indexOf(currentValue) !== -1) {
    return {
      fontWeight: 500,
      backgroundColor: '#D4E0EE',
      color: theme.palette.primary.main
    };
  }
  return {};
}

const MultipleSelect: React.FC<SectionFieldContainerProps> = (multiSelectProps) => {
  const templateScoreContext = React.useContext<TemplateScoreContextType>(TemplateScoreContext);
  const classes = useStyles();
  const theme = useTheme();
  const [multiSelectValue, setMultiSelectValue] = React.useState<number[]>([]);
  const { isMobile, sectionFieldDetails, sectionRank } = multiSelectProps;
  const { name, isMandatory, selectOptions, rank, extra } = sectionFieldDetails;
  const optionByRankMap: { [rank: SectionFieldOption['rank']]: SectionFieldOption } = selectOptions.reduce(
    (map, option) => ({ ...map, [option.rank]: option }),
    {}
  );
  const usedForScore = extra?.usedForScore ?? false;
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedOptionsRank = (event.target.value as number[]) ?? [];
    let totalScores = 0;
    selectedOptionsRank.forEach((_rank) => {
      const option = optionByRankMap[_rank];
      if (option && usedForScore && option.score) totalScores += option.score;
    });
    templateScoreContext.updateTemplateScoreMap(sectionRank, rank, totalScores, usedForScore);

    setMultiSelectValue(selectedOptionsRank);
  };

  return (
    <FormControl variant='outlined' fullWidth size={isMobile ? 'small' : 'medium'} required={isMandatory}>
      <InputLabel classes={{ root: classes.inputLabel }} id='multi-select-outlined-label'>
        {name}
      </InputLabel>
      <Select
        labelId='multi-select-outlined-label'
        label={name}
        multiple
        value={multiSelectValue}
        onChange={handleChange}
        renderValue={(selected) => (
          <div className={classes.multiSelectChips}>
            {(selected as number[]).map((value, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={value + i}>
                {multiSelectProps.isMobile ? (
                  <Chip key={value} label={optionByRankMap[value].name} className={classes.multiSelectChip} />
                ) : (
                  <Button className={classes.multiSelectButton} variant='contained' color='default' key={value}>
                    {optionByRankMap[value].name}
                  </Button>
                )}
              </div>
            ))}
          </div>
        )}
        MenuProps={{
          getContentAnchorEl: () => null as any
        }}
      >
        {selectOptions?.map((eachOption: SectionFieldOption) => (
          <MenuItem key={eachOption.rank} value={eachOption.rank} style={getStyles(eachOption.rank, multiSelectValue, theme)}>
            {eachOption.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelect;
