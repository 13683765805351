import * as React from 'react';
import { Checkbox, FormControlLabel, FormControl } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SectionFieldContainerProps, TemplateScoreContextType } from 'typings';
import { TemplateScoreContext } from '../../contexts/TemplateScoreContext';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '100%',
      height: '100%',
      zIndex: 0,
      textAlign: 'left',
      display: 'felx',
      flexDirection: 'row',
      alignItems: 'center'
    },
    checkboxLabel: {
      fontSize: '1rem !important',
      color: '#5182bb'
    },
    required: {
      marginRight: '5px',
      color: '#5182bb'
    }
  })
);

const CheckBox: React.FC<SectionFieldContainerProps> = (checkboxProps) => {
  const templateScoreContext = React.useContext<TemplateScoreContextType>(TemplateScoreContext);
  const { isMobile, sectionFieldDetails, sectionRank } = checkboxProps;
  const { name, isMandatory, rank, extra } = sectionFieldDetails;
  const usedForScore = extra?.usedForScore ?? false;
  const score = extra?.score ?? 0;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    templateScoreContext.updateTemplateScoreMap(sectionRank, rank, event.target.checked ? score : 0, usedForScore);
  };

  const classes = useStyles();
  return (
    <>
      <FormControl variant='outlined' size={isMobile ? 'small' : 'medium'} classes={{ root: classes.root }}>
        <FormControlLabel
          control={<Checkbox size='medium' classes={{ checked: 'checked-box' }} required={isMandatory} onChange={handleChange} />}
          key={rank}
          label={name}
          classes={{ label: classes.checkboxLabel }}
        />
      </FormControl>
    </>
  );
};

export default CheckBox;
