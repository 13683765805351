import { createStyles, makeStyles } from '@material-ui/core/styles';
import { globalColors } from './globalColorVariables';

const useWorkflowDialogStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      borderRadius: '28px'
    },
    dialogClose: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    workflowDialogContent: {
      textAlign: 'center',
      ' & div': {
        margin: '1rem 0'
      }
    },
    nlBtn: {
      borderRadius: '20px',
      backgroundColor: '#2864AC',
      fontWeight: 500,
      color: '#fff'
    },
    learnBtn: {
      color: globalColors.primaryColor,
      textDecoration: 'none'
    }
  })
);

export default useWorkflowDialogStyles;
