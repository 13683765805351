/*
 * Original source code: https://github.com/camsong/fetch-jsonp
*/
export function submitPardotForm(url: string) {

  return new Promise((resolve, reject) => {
    if (url === '') reject(false);

    var jsonpScript = document.createElement('script');
    jsonpScript.setAttribute('src', '' + url);
    document.getElementsByTagName('head')[0].appendChild(jsonpScript);

    // handle callback from Pardot success/error response automatically after the form is submitted. JS files stored in Strapi backend.
    window.callback = function (res: any) {
      if (res.result === 'done') {
        resolve(true);
      } else {
        reject(false);
      }
    }
  })
}
