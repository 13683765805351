import * as React from 'react';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter, Typography } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionFieldContainerProps } from 'typings';
import { getActionTextByLanguage } from '../../utils/getAllSupportedLanguages';
import { FieldInputType } from '../../utils/fieldInputType';
import { matchesMobile } from '../../utils/mediaQuery';
import useTableStyles from '../../styles/TableField';
import FieldTitle from './FieldTitle';
import EmptyColumn from './EmptyColumn';

const getColumnWidthByType = (type: string) => {
  switch (type) {
    case FieldInputType.ShortText:
    case FieldInputType.LongText:
    case FieldInputType.NumberDecimal:
    case FieldInputType.NumberInteger:
    case FieldInputType.Signature:
      return 118;
    case FieldInputType.Date:
    case FieldInputType.DateAndTime:
      return 168;
    case FieldInputType.SingleSelect:
    case FieldInputType.MultiSelect:
      return 218;
    default:
      return 100;
  }
};

const TableField: React.FC<SectionFieldContainerProps> = (tableFieldProps) => {
  const { isMobile, sectionFieldDetails, templateLanguage } = tableFieldProps;
  const { name, isMandatory, subFields } = sectionFieldDetails;
  const tableRef = React.useRef(null);
  const [isEmptyColumn, setEmptyColumn] = React.useState(false);
  // it's either on mobile preview or real mobile
  const isMobileScreen = isMobile || matchesMobile();
  const classes = useTableStyles();
  const columnHeaders = subFields || [];
  const actionText = getActionTextByLanguage(templateLanguage, isMobile);
  const totalHeaderWidth = columnHeaders.reduce((totalWidth, header) => totalWidth + getColumnWidthByType(header.fieldInputType), 0);
  const hasCalculation = columnHeaders.some((header) => header.extra?.calculation);

  React.useEffect(() => {
    if (tableRef.current && totalHeaderWidth < tableRef.current.offsetWidth) setEmptyColumn(true);
  }, [totalHeaderWidth]);

  return (
    <>
      <FieldTitle name={name} isMandatory={isMandatory} />
      <Paper ref={tableRef} style={{ boxShadow: 'unset' }}>
        <TableContainer className={classes.tableContainer}>
          <Table classes={{ root: classes.tableRoot }}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {columnHeaders.map((header) => (
                  <TableCell
                    key={header.rank}
                    align='left'
                    style={{
                      minWidth: `${getColumnWidthByType(header.fieldInputType)}px`,
                      maxWidth: `${getColumnWidthByType(header.fieldInputType)}px`
                    }}
                    classes={{ root: classes.tableCellRoot }}
                  >
                    {header.name}
                  </TableCell>
                ))}
                <EmptyColumn isEmpty={isEmptyColumn} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {columnHeaders.map((header) => (
                  <TableCell key={header.rank} align='left' classes={{ root: classes.tableCellRoot }} className={classes.tableRow}>
                    -
                  </TableCell>
                ))}
                <EmptyColumn isEmpty={isEmptyColumn} />
              </TableRow>
            </TableBody>
            <TableFooter className={classes.tableFooter}>
              {hasCalculation ? (
                <TableRow data-testid='calculation-row'>
                  {columnHeaders.map((header) => (
                    <TableCell
                      key={header.rank}
                      align='left'
                      classes={{ root: classes.tableCellRoot }}
                      className={`${classes.tableRow} ${classes.calculationRow}`}
                    >
                      {header?.extra?.calculation ? (
                        <>
                          {header?.extra?.calculation}
                          <br />-
                        </>
                      ) : null}
                    </TableCell>
                  ))}
                  <EmptyColumn isEmpty={isEmptyColumn} />
                </TableRow>
              ) : null}
            </TableFooter>
          </Table>
        </TableContainer>

        <TableContainer className={classes.tableContainer} style={{ overflowX: 'hidden' }}>
          {isMobileScreen ? (
            <div className={classes.actionButtonContainer}>
              <Typography variant='caption' className={classes.tableActionButtonTxt}>
                +&nbsp;&nbsp;{actionText}
              </Typography>
            </div>
          ) : (
            <div className={classes.actionButtonContainer}>
              <StaticImage
                width={20}
                layout='constrained'
                placeholder='tracedSVG'
                src='../../assets/TemplatePreview/edit_filled.svg'
                alt='Edit Table'
                style={{ verticalAlign: 'middle' }}
              />
              <Typography variant='caption' className={classes.tableActionButtonTxt}>
                {actionText}
              </Typography>
            </div>
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

export default TableField;
