import * as React from 'react';
import { Handle, Position, NodeToolbar } from '@xyflow/react';
import { IconButton, Paper, Typography } from '@material-ui/core';
import ArrowForwardIosRounded from '@material-ui/icons/ArrowForwardIosRounded';
import WorkflowDialog from './WorkflowDialog';

const ToolbarNode: React.FC<any> = ({ data }) => {
  const { hasIncomingLink, hasOutgoingLink } = data;
  const [openWorkflowDialog, setOpenWorkflowDialog] = React.useState(false);

  const handleDialogOpen = () => {
    setOpenWorkflowDialog(true);
  };

  const handleDialogClose = () => {
    setOpenWorkflowDialog(false);
  };

  return (
    <div style={{ pointerEvents: 'all' }}>
      <NodeToolbar isVisible position={Position.Right}>
        <IconButton aria-label='tooltip' onClick={handleDialogOpen} style={{ width: '0.5rem', height: '0.5rem' }}>
          <ArrowForwardIosRounded style={{ fontSize: '0.45rem' }} />
        </IconButton>
      </NodeToolbar>
      <Paper onClick={handleDialogOpen} style={{ padding: '0.4rem 0.95rem', borderRadius: '0.2rem', minWidth: '100px' }}>
        <div
          style={{
            width: '8px',
            height: '8px',
            backgroundColor: data.color,
            borderRadius: '50%',
            marginRight: '8px',
            display: 'inline-block'
          }}
        />
        <Typography variant='caption'>{data.label}</Typography>
      </Paper>
      {hasIncomingLink ? <Handle type='target' position={Position.Top} /> : null}
      {hasOutgoingLink ? <Handle type='source' position={Position.Bottom} /> : null}
      <WorkflowDialog open={openWorkflowDialog} dialogType='state' onDialogClose={handleDialogClose} />
    </div>
  );
};

export default React.memo(ToolbarNode);
