import * as React from 'react';
import { SectionFieldContainerProps } from 'typings';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FieldTitle from './FieldTitle';
import useStyles from '../../styles/componentStyle';

const Formula: React.FC<SectionFieldContainerProps> = ({ sectionFieldDetails, isMobile }) => {
  const classes = useStyles();
  const { name, isMandatory } = sectionFieldDetails;

  return (
    <FormControl fullWidth variant='outlined' size={isMobile ? 'small' : 'medium'} classes={{ root: classes.root }}>
      <FieldTitle name={name} isMandatory={isMandatory} />
      <OutlinedInput value='-' disabled />
    </FormControl>
  );
};

export default Formula;
