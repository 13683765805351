import { createStyles, makeStyles } from '@material-ui/core/styles';

const useTemplateSectionStyle = makeStyles(() =>
  createStyles({
    templateSectionTitleWeb: {
      fontSize: '1rem',
      fontWeight: 500,
      backgroundColor: 'white'
    },
    templateSectionTitleMobile: {
      fontSize: '0.75rem',
      fontWeight: 600,
      backgroundColor: '#f1f1f1',
      padding: '0.3rem 0',
      textAlign: 'center'
    }
  })
);

export default useTemplateSectionStyle;
