import { Template, SectionField, TemplateScoreMap, ScoreByRankMap, NovadeLiteTemplateInput } from 'typings';
import { FieldInputType } from './fieldInputType';

export function getMaxSectionPreviewScore(sectionFields: SectionField[]) {
  let scores = 0;
  const defaultScore = 0;
  let scoreEnabled = false;
  sectionFields.forEach((sectionField) => {
    const { extra, selectOptions, fieldInputType } = sectionField;
    const usedForScore = extra?.usedForScore ?? false;
    if (usedForScore) {
      scoreEnabled = usedForScore;
      switch (fieldInputType) {
        case FieldInputType.Checkbox:
          scores += extra?.score ?? defaultScore;
          break;
        case FieldInputType.SingleSelect:
        case FieldInputType.Toggle: {
          const maxScore = selectOptions.reduce((max, option) => {
            const score = option.score ?? defaultScore;
            return max > score ? max : score;
          }, defaultScore);
          scores += maxScore;
          break;
        }
        case FieldInputType.MultiSelect: {
          const scoreSum = selectOptions.reduce((total, option) => total + (option.score ?? defaultScore), defaultScore);
          scores += scoreSum;
          break;
        }
        default:
      }
    }
  });

  // Skip if all the fields disable the score feature
  return scoreEnabled ? scores : null;
}

export function calculateTotalScores(scoreMap: TemplateScoreMap | ScoreByRankMap): number {
  return Object.values(scoreMap).reduce((sum: number, val: any) => sum + (typeof val === 'object' ? calculateTotalScores(val) : val), 0);
}

export function getTotalScoresBySectionMap(template: NovadeLiteTemplateInput) {
  const { templateSections } = template;
  return templateSections.reduce((scoreMap: any, templateSection) => {
    const { rank, sectionFields } = templateSection;
    const sectionScores = getMaxSectionPreviewScore(sectionFields);
    return sectionScores !== null ? { ...scoreMap, [rank]: sectionScores } : scoreMap;
  }, {});
}

export function getScoreDisplayValues(templateScoreMap: TemplateScoreMap | ScoreByRankMap, maxScores: number) {
  const totalScore = calculateTotalScores(templateScoreMap);
  const percentage = maxScores ? (totalScore / maxScores) * 100 : 0;
  return `${totalScore} / ${maxScores} (${Number.parseFloat(percentage.toFixed(2))}%)`;
}
