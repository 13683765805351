import { createStyles, makeStyles } from '@material-ui/core/styles';

const useWorkflowStyles = makeStyles(() =>
  createStyles({
    tabRoot: {
      backgroundColor: 'rgba(40, 100, 172, 0.04)',
      color: '#000',
      fontWeight: 400,
      fontSize: '0.7em'
    },
    tabBorder: {
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderColor: 'rgba(224, 224, 224, 1)',
      borderWidth: '1px 1px 0 1px'
    },
    selectedTab: {
      backgroundColor: '#fff',
      color: 'rgba(40, 100, 172, 1)'
    },
    tabIndicator: {
      backgroundColor: '#fff'
    }
  })
);

export default useWorkflowStyles;
