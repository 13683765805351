import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { SectionFieldContainerProps } from 'typings';
import { FieldInputType } from '../../utils/fieldInputType';
import useStyles from '../../styles/componentStyle';

const TextField: React.FC<SectionFieldContainerProps> = ({ sectionFieldDetails, isMobile }) => {
  const { isMandatory, name, rank, fieldInputType } = sectionFieldDetails;
  const labelFor = `${name}-${rank}-${isMobile}`;

  const [value, setValue] = React.useState('');
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const isLongText = fieldInputType === FieldInputType.LongText;
  const classes = useStyles();

  return (
    <FormControl fullWidth variant='outlined' size={isMobile ? 'small' : 'medium'} required={isMandatory}>
      <InputLabel classes={{ root: classes.inputLabel }} htmlFor={labelFor}>
        {name}
      </InputLabel>
      <OutlinedInput
        id={labelFor}
        value={value}
        onChange={handleValueChange}
        label={name}
        multiline={isLongText}
        rows={isLongText ? 4 : 1}
      />
    </FormControl>
  );
};

export default TextField;
