import * as React from 'react';
import { WorkflowProps } from 'typings';
import { ReactFlow, useNodesState, useEdgesState, ReactFlowProvider } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import ToolbarNode from './Workflow/ToolbarNode';
import AnnotationNode from './Workflow/AnnotationNode';
import '../styles/workflowOverview.css';
import ActionEdge from './Workflow/ActionEdge';
import { generateWorkflowChart } from '../utils/workflowutils';

const nodeTypes = {
  annotation: AnnotationNode,
  tools: ToolbarNode
};

const edgeTypes = {
  action: ActionEdge
};

export const Workflow: React.FC<WorkflowProps> = ({ workflowSteps, workflowActions, templatePageContent }) => {
  const workflowsMap = generateWorkflowChart(workflowSteps, workflowActions, templatePageContent);

  const [nodes, , onNodesChange] = useNodesState(workflowsMap.nodes);
  const [edges, , onEdgesChange] = useEdgesState(workflowsMap.edges);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      attributionPosition='bottom-right'
      fitView
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      style={{ width: '100%', height: '100%', margin: '0 auto' }}
      className='overview'
      minZoom={0.8}
      maxZoom={3}
    />
  );
};

const TemplateWorkflowProvider: React.FC<WorkflowProps> = (props) => (
  <ReactFlowProvider>
    <Workflow {...props} />
  </ReactFlowProvider>
);

export default TemplateWorkflowProvider;
