import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';
import { SectionFieldOption, SectionFieldContainerProps, TemplateScoreContextType } from '../../typings';
import useStyles from '../../styles/componentStyle';
import { TemplateScoreContext } from '../../contexts/TemplateScoreContext';

const SingleSelect: React.FC<SectionFieldContainerProps> = (singleSelectProps) => {
  const templateScoreContext = React.useContext<TemplateScoreContextType>(TemplateScoreContext);
  const [singleValue, setSingleValue] = React.useState(0);
  const classes = useStyles();
  const { isMobile, sectionFieldDetails, sectionRank } = singleSelectProps;
  const { name, isMandatory, selectOptions, rank, extra } = sectionFieldDetails;
  const usedForScore = extra?.usedForScore ?? false;
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const score = (event.target.value as number) ?? 0;
    templateScoreContext.updateTemplateScoreMap(sectionRank, rank, score, usedForScore);
    setSingleValue(score);
  };
  return (
    <FormControl fullWidth variant='outlined' size={isMobile ? 'small' : 'medium'} required={isMandatory}>
      <InputLabel classes={{ root: classes.inputLabel }} id='single-select-outlined-label'>
        {name}
      </InputLabel>
      <Select label={name} labelId='single-select-outlined-label' value={singleValue} onChange={handleChange}>
        {selectOptions?.map((eachOption: SectionFieldOption) => (
          <MenuItem value={usedForScore ? eachOption.score : 0} key={eachOption.rank}>
            {eachOption.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;
