import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import useScoreStyles from '../../styles/Score';
import { ScoreProps } from '../../typings';

const Score: React.FC<ScoreProps> = ({ label, scoreValue, isMobile }) => {
  const classes = useScoreStyles();
  const textAlign = isMobile ? classes.textCenter : '';
  if (scoreValue)
    return (
      <Typography variant='body2' className={`${classes.scoreText} ${textAlign}`}>
        {label}: <span className={classes.scoreTag}>{scoreValue}</span>
      </Typography>
    );

  return null;
};

export default Score;
