import * as React from 'react';
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge, Edge } from '@xyflow/react';
import { Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import WorkflowDialog from './WorkflowDialog';

const ActionEdge: React.FC<EdgeProps<Edge<{ label: string }>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd
}) => {
  const theme = useTheme();
  const [openWorkflowDialog, setOpenWorkflowDialog] = React.useState(false);

  const handleDialogOpen = () => {
    setOpenWorkflowDialog(true);
  };

  const handleDialogClose = () => {
    setOpenWorkflowDialog(false);
  };

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />
      <EdgeLabelRenderer>
        <Paper
          onClick={handleDialogOpen}
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: data?.bgColor || '#ffcc00',
            padding: '0.3rem 0.6rem',
            borderRadius: 4,
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.65rem',
            fontWeight: 400,
            pointerEvents: 'all'
          }}
        >
          <Typography variant='caption' style={{ color: data?.color }}>
            {data?.label}
          </Typography>
        </Paper>
      </EdgeLabelRenderer>
      <WorkflowDialog open={openWorkflowDialog} dialogType='action' onDialogClose={handleDialogClose} />
    </>
  );
};

export default ActionEdge;
