import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import TemplateSectionContainer from './TemplateSectionContainer';
import { TemplateContainerProps, TemplateScoreContextType } from '../typings';
import { globalColors } from '../styles/globalColorVariables';
import { TemplateScoreContext } from '../contexts/TemplateScoreContext';
import Score from './SectionFields/Score';
import { calculateTotalScores, getScoreDisplayValues } from '../utils/previewScoreUtils';

const useStyles = makeStyles({
  root: {
    zIndex: 1
  },
  disabled: {
    opacity: 0
  },
  phoneContainer: {
    border: `0.75rem solid ${globalColors.phoneOutlineColor}`,
    borderBottom: `2rem solid ${globalColors.phoneOutlineColor}`,
    borderTop: `2rem solid ${globalColors.phoneOutlineColor}`,
    borderRadius: '2rem',
    height: '29rem',
    /* minWidth: "17rem", */
    width: '17rem',
    overflow: 'auto',
    backgroundColor: '#f1f1f1'
  },
  titleText: {
    fontSize: '0.875rem',
    padding: '1rem',
    fontWeight: 500,
    textAlign: 'center'
  },
  formFieldContainer: {
    padding: '1rem',
    margin: '6px',
    marginBottom: '1.5rem'
  },
  fabContainer: {
    padding: '1rem',
    position: 'sticky',
    bottom: 0
  },
  fabPrev: {
    marginRight: '0.5rem'
  }
});

const MobileFormContainer: React.FC<TemplateContainerProps> = ({ templateDetails, templateLanguage, templateMaxScoreMap }) => {
  const templateScore = React.useContext<TemplateScoreContextType>(TemplateScoreContext);
  const templateScoreEnabled = Object.keys(templateMaxScoreMap ?? {}).length > 0;
  const displayedScoreStr = templateScoreEnabled
    ? getScoreDisplayValues(templateScore.templateScoreMap ?? {}, calculateTotalScores(templateMaxScoreMap ?? {}))
    : '';
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const { name, templateSections } = templateDetails;
  const maxSteps = templateSections.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className={classes.phoneContainer}>
      <AppBar position='static' color='primary'>
        <Typography variant='body1' className={classes.titleText}>
          {name}
        </Typography>
      </AppBar>
      <Score label='Total Score' scoreValue={displayedScoreStr} isMobile />
      <div>
        <TemplateSectionContainer
          isMobile
          templateSection={templateSections[activeStep]}
          templateLanguage={templateLanguage}
          templateMaxScoreMap={templateMaxScoreMap}
        />
      </div>
      <div className={classes.fabContainer}>
        <Fab
          size='medium'
          classes={{ root: `${classes.root} ${classes.fabPrev}`, disabled: `${classes.disabled} ${classes.fabPrev}` }}
          aria-label='previous page'
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          <ChevronLeft />
        </Fab>
        <Fab
          size='medium'
          classes={{ root: classes.root, disabled: classes.disabled }}
          aria-label='next page'
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          <ChevronRight />
        </Fab>
      </div>
    </div>
  );
};

export default MobileFormContainer;
