import { gql, useMutation } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { graphql } from 'gatsby';
import React from 'react';
import LoginDialog from '../components/LoginDialog';
import ActionButtonsWeb from '../components/Template/ActionButtonsWeb';
import { Languages, defaultLocation } from '../utils/getAllSupportedLanguages';
import MobileFormContainer from '../components/MobileFormContainer';
import PardotEmailDialog from '../components/PardotEmailDialog';
import Seo from '../components/Seo';
import TemplateSeoContent from '../components/Template/TemplateSeoContent';
import TemplateInfoSection from '../components/Template/TemplateInfoSection';
import WorkspaceDialog from '../components/WorkspaceDialog';
import { AuthUserContext } from '../contexts/AuthUserContext';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { NovadeLiteUrlContext } from '../contexts/NovadeLiteUrlContext';
import {
  AuthUserContextType,
  DatabasesMap,
  LocationLanguageContextType,
  LogInResponse,
  NovadeLiteUrlContextType,
  NovadeLiteUser,
  StrapiTemplate,
  Template,
  StrapiTemplatePageQueryProps,
  NovadeLiteTemplateInput
} from '../typings';
import { exportToPDF } from '../utils/exportPDF';
import { getAttachmentData, getEmailValues, sendEmail, setEmailValues } from '../utils/sendStrapiEmail';
import { setGTMEvent } from '../utils/setGAEvent';
import { getSortedTemplate, getTemplateValues, updateTemplateCountryCode } from '../utils/templateValues';
import { matchesMobile } from '../utils/mediaQuery';
import useWorkspacesWithRights from '../hooks/useWorkspacesWithRights';
import { getTotalScoresBySectionMap } from '../utils/previewScoreUtils';
import ActionButtonsMobile from '../components/Template/ActionButtonsMobile';
import TemplateScoreProvider from '../contexts/TemplateScoreContext';
import TemplateSection from '../components/Template/TemplateSection';

interface TemplateProps {
  data: {
    strapiTemplate: StrapiTemplate;
    allStrapiTemplatePage: {
      nodes: StrapiTemplatePageQueryProps[];
    };
  };
}

export const query = graphql`
  query TemplateQuery($id: Int!) {
    strapiTemplate(strapiId: { eq: $id }) {
      id
      published_at
      strapiId
      Template {
        name
        templateGroup {
          name
        }
        startStepID
        templateSections {
          name
          rank
          sectionFields {
            name
            rank
            extra {
              formula
              galleryAllowed
              textValidation
              usedForScore
              score
            }
            isMandatory
            fieldInputType
            dataMappingKeyID
            showInPdfExport
            galleryAllowed
            textValidation
            selectOptions {
              color
              name
              rank
              score
            }
            subFields {
              fieldInputType
              name
              rank
              selectOptions {
                color
                name
                rank
              }
              extra {
                calculation
                formula
                galleryAllowed
                textValidation
              }
            }
          }
        }
        workflowActions {
          fromStepID
          toStepID
        }
        workflowSteps {
          color
          id
          name
          phase
          x
          y
        }
        tags {
          color
          name
        }
      }
      Title
      Description
      Keywords
      Author
      SeoContent
      countries {
        Localizations {
          locale
          localeName
          displayName
        }
        name
      }
    }
    allStrapiTemplatePage {
      nodes {
        PublishedDateLabel
        AuthorLabel
        SaveToNovadeLiteLabel
        locale
        WebPreviewLabel
        MobilePreviewLabel
        DownloadPDFLabel
        SuccessMessage
        ErrorMessage
        DownloadLabel
        WorkspaceLabel
        WorkspaceButton
        FormFieldsSection
        WorkflowSection
        Action
        ActionTooltip
        Closing
        ClosingTooltip
        Preparation
        PreparationTooltip
        TagsLabel
        ActionPrefix
      }
    }
  }
`;

const TemplatePage: React.FC<TemplateProps> = ({ data }) => {
  const template = data.strapiTemplate;
  const templateDetails: NovadeLiteTemplateInput = getSortedTemplate({ ...template.Template, ...{ id: template.id } });
  const templateLanguage = template.template_language || Languages.English;
  const [openWorkspace, setOpenWorkspace] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState<string | undefined>('');
  const authUser = React.useContext<AuthUserContextType | null>(AuthUserContext);
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language = locationLanguage ? locationLanguage.language : Languages.English;
  const country = locationLanguage?.country ?? defaultLocation;
  const contextUrl = React.useContext<NovadeLiteUrlContextType | null>(NovadeLiteUrlContext);
  const databasesMap = contextUrl ? authUser?.databasesMap[contextUrl.novadeLiteUrl] || {} : {};
  const { token } = Object.values(databasesMap)[0] ?? '';
  const [workspacesWithRightsMap, setWorkspacesWithRightsMap] = React.useState<DatabasesMap>({});

  const templatePageContent = data.allStrapiTemplatePage.nodes.find((x: any) => x.locale === language);
  // Get the total scores
  const templateMaxScoreMap = React.useMemo(() => getTotalScoresBySectionMap(templateDetails), [template?.id]);

  const { loading: loading1, error, workspacesMap } = useWorkspacesWithRights(contextUrl?.novadeLiteUrl ?? '', token, databasesMap);
  React.useEffect(() => {
    if (!loading1 && !error) {
      setWorkspacesWithRightsMap(workspacesMap);
    }
  }, [workspacesMap, loading1, error]);

  const openWorkspaceDialog = () => {
    setOpenWorkspace(true);
  };

  const exportPDF = (userData: NovadeLiteUser) => {
    const templateValues = getTemplateValues();
    updateTemplateCountryCode(country.locationCode);
    const pdfEndpoint = `${contextUrl?.novadeLiteUrl}/portal-template-pdf`;
    exportToPDF(pdfEndpoint, templateValues).then((fileData) => {
      const attachmentData = getAttachmentData(fileData.fileContent, fileData.fileName, fileData.fileType);
      setEmailValues(userData, language, template, attachmentData!);
      const emailMsg = getEmailValues();
      sendEmail(emailMsg);
    });
    setGTMEvent('Download Template as PDF', 'download_PDF', templateValues.name);
  };

  const handleDialogClose = (isSuccessfulSave: any | undefined) => {
    setOpenWorkspace(false);
    if (typeof isSuccessfulSave !== 'undefined') {
      if (isSuccessfulSave) {
        setSnackBarMsg(templatePageContent?.SuccessMessage);
      } else {
        setSnackBarMsg(templatePageContent?.ErrorMessage);
      }
      setOpenSnackbar(true);
    }
  };

  const handleEmailDialogClose = (isSuccessfulSubmit: boolean | undefined, inputEmail: string | undefined) => {
    setOpenEmail(false);
    if (isSuccessfulSubmit) {
      const inputUserData: NovadeLiteUser = {
        email: inputEmail!,
        displayName: '' // guest
      };
      exportPDF(inputUserData);
    }
  };
  const handleSnackbarClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // to save directly to Novade Lite without opening Workspace Dialog if user only has 1 workspace
  const UPSERT_TEMPLATE_DATE = gql`
    mutation upsertTemplateData($template: TemplatePortalInput!) {
      savePortalTemplateToWorkspace(template: $template)
    }
  `;

  const [upsertTemplateData, { loading }] = useMutation<{ savePortalTemplateToWorkspace: any }, { template: NovadeLiteTemplateInput }>(
    UPSERT_TEMPLATE_DATE,
    {
      variables: {
        template: templateDetails
      },
      context: {
        uri: `${contextUrl?.novadeLiteUrl}/graphql`,
        headers: {
          Authorization: Object.values(databasesMap).length !== 0 ? `Bearer ${Object.values(databasesMap)[0].token}` : 'No Auth'
        }
      },
      onCompleted({ savePortalTemplateToWorkspace }) {
        if (savePortalTemplateToWorkspace) {
          setGTMEvent('Save template to Novade Lite workspace', 'save_To_Novade_Lite', templateDetails.name);
          setSnackBarMsg(templatePageContent?.SuccessMessage);
          setOpenSnackbar(true);
        }
      },
      onError({ graphQLErrors, networkError }) {
        if (graphQLErrors || networkError) {
          setSnackBarMsg(templatePageContent?.ErrorMessage);
          setOpenSnackbar(true);
        }
      }
    }
  );
  const openLoginDialog = () => {
    setOpenLogin(true);
  };

  const handleLoginDialogClose = (value: LogInResponse | undefined) => {
    if (value) {
      authUser?.updateUser({
        email: value.email,
        displayName: value.userDisplayName
      });
      authUser?.updateDatabasesMap(value.databases);
    }
    setOpenLogin(false);
  };

  const onSaveToNovadeLite = () => {
    if (authUser?.user) {
      const workspaces = Object.keys(workspacesWithRightsMap);
      if (workspaces.length === 1) {
        upsertTemplateData();
      } else if (workspaces.length > 1) openWorkspaceDialog();
      else {
        setSnackBarMsg("Sorry you don't have permission to import templates to a workspace. Please contact the admin.");
        setOpenSnackbar(true);
      }
    } else openLoginDialog();
  };

  const handleDownload = () => {
    // no need for email request if user already logged in with NL account
    if (Object.values(databasesMap).length >= 1) {
      exportPDF(authUser?.user!);
    } else {
      setOpenEmail(true);
    }
  };

  const isMobile = matchesMobile();
  const templateInfoProps = {
    isMobile,
    templateTitle: template.Title,
    authorLabel: templatePageContent?.AuthorLabel,
    author: template.Author,
    description: template.Description,
    publishedDateLabel: templatePageContent?.PublishedDateLabel,
    publishedDate: template.published_at,
    tags: templateDetails.tags,
    tagsLabel: templatePageContent?.TagsLabel
  };

  if (templatePageContent) {
    return (
      <TemplateScoreProvider>
        <Seo title={template.Title} description={template.Description} keywords={template.Keywords} isTemplatePage />
        <Container>
          <br />
          <Grid container spacing={6}>
            <Grid item xs={12} md={8}>
              <TemplateInfoSection {...templateInfoProps} />
              <TemplateSection
                templateDetails={templateDetails}
                templateLanguage={templateLanguage}
                templateMaxScoreMap={templateMaxScoreMap}
                templatePageContent={templatePageContent}
              />
            </Grid>
            {!isMobile ? (
              <Grid item xs={12} md={4}>
                <div style={{ position: 'sticky', top: '5.5rem' }}>
                  <ActionButtonsWeb
                    templatePageContent={templatePageContent}
                    exportPDF={handleDownload}
                    onSaveToNL={onSaveToNovadeLite}
                    loading={loading || loading1}
                  />
                  <MobileFormContainer
                    templateDetails={templateDetails}
                    templateLanguage={templateLanguage}
                    templateMaxScoreMap={templateMaxScoreMap}
                  />
                </div>
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={1}>
            <TemplateSeoContent isMobile={isMobile} seoContent={template.SeoContent} textColor='#000' />
          </Grid>
        </Container>
        {isMobile ? (
          <ActionButtonsMobile
            templatePageContent={templatePageContent}
            exportPDF={handleDownload}
            onSaveToNL={onSaveToNovadeLite}
            loading={loading || loading1}
          />
        ) : null}
        <WorkspaceDialog
          open={openWorkspace}
          onDialogClose={handleDialogClose}
          template={templateDetails}
          selectLabel={templatePageContent.WorkspaceLabel}
          btnLabel={templatePageContent.WorkspaceButton}
          workspaces={workspacesWithRightsMap}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={openSnackbar}
          onClose={handleSnackbarClose}
          autoHideDuration={5000}
          message={snackBarMsg}
        />
        <LoginDialog open={openLogin} onDialogClose={handleLoginDialogClose} />
        <PardotEmailDialog open={openEmail} templateTitle={template.Title} onDialogClose={handleEmailDialogClose} />
      </TemplateScoreProvider>
    );
  }

  return <Typography variant='body1' />;
};
export default TemplatePage;
