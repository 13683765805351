import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionFieldContainerProps } from 'typings';
import FieldTitle from './FieldTitle';

const Signature: React.FC<SectionFieldContainerProps> = (signatureProps) => {
  const { sectionFieldDetails } = signatureProps;
  const { name, isMandatory } = sectionFieldDetails;
  return (
    <div>
      <FieldTitle name={name} isMandatory={isMandatory} />
      <br />
      <StaticImage layout='constrained' placeholder='tracedSVG' src='../../assets/Signature/tolstoy-romerio.svg' alt='sample signature' />
    </div>
  );
};

export default Signature;
