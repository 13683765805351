import * as React from 'react';
import { Chip } from '@material-ui/core';
import { TagsProps } from 'typings';
import { convertARGBNumberToRGBHexString, getFontColor } from '../../utils/common';

const TemplateTags: React.FC<TagsProps> = ({ tags }) => (
  <>
    {tags.map((tag) => {
      const backgroundColor = convertARGBNumberToRGBHexString(tag.color);
      const fontColor = getFontColor(backgroundColor);
      return (
        <Chip
          label={tag.name}
          style={{
            backgroundColor: `${backgroundColor}`,
            padding: '0.25rem',
            margin: '0.1rem',
            color: `${fontColor}`,
            fontSize: '0.7rem',
            borderRadius: '12px'
          }}
        />
      );
    })}
  </>
);

export default TemplateTags;
