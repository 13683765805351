import React from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import useTemplateStyles from '../../styles/templateStyle';

const ActionButtonsMobile: React.FC<any> = ({ templatePageContent, exportPDF, onSaveToNL }) => {
  const classes = useTemplateStyles();
  const handleExport = () => {
    exportPDF();
  };
  const handleSaveToNL = () => {
    onSaveToNL();
  };

  return (
    <AppBar position='sticky' color='primary' className={classes.mobileFooter}>
      <Toolbar className={classes.mobileFooterToolbar}>
        <Button onClick={handleSaveToNL}>{templatePageContent.SaveToNovadeLiteLabel}</Button>
        <Button color='primary' onClick={handleExport}>
          {templatePageContent.DownloadLabel}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default ActionButtonsMobile;
