import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Languages } from '../utils/getAllSupportedLanguages';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { LocationLanguageContextType, PardotEmailDialogProps } from '../typings';
import { submitPardotForm } from '../utils/submitPardotForm';

interface PardotFormState {
  email: string;
  newsletter: boolean;
  isEmailInvalid: boolean;
  error: string;
}

const { GATSBY_PARDOT_ENDPOINT_URL } = process.env;

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      maxWidth: '480px'
    },
    dialogClose: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    dialogFormContainer: {
      display: 'flex',
      padding: '0rem 3rem 2rem 3rem',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    dialogTitle: {
      textAlign: 'center',
      paddingBottom: '0.75em',
      '& p, h6': {
        paddingBottom: '0.5em'
      },
      '& h6': {
        fontWeight: '600'
      }
    },
    formControl: {
      margin: '1rem 0',
      '& span, p': {
        fontSize: '0.75rem'
      }
    },
    markdownText: {
      '& a': {
        textDecoration: 'none'
      },
      textAlign: 'left'
    },
    containedButtn: {
      color: '#fff !important',
      textTransform: 'uppercase'
    },
    disabledButton: {
      backgroundColor: '#cee7b7 !important'
    }
  })
);

const PardotEmailDialog: React.FC<PardotEmailDialogProps> = (pardotEmailDialogProps) => {
  const data = useStaticQuery(
    graphql`
      query PardotEmailDialogQuery {
        allStrapiPardotEmailDialog {
          nodes {
            EmailLabel
            ErrorLabel
            NewsletterLabel
            DownloadLabel
            locale
          }
        }
      }
    `
  );

  const classes = useStyles();
  const { open, templateTitle, onDialogClose } = pardotEmailDialogProps;

  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const emailDialogContent = data.allStrapiPardotEmailDialog.nodes.find((x: any) => x.locale === language);

  const initialValues = {
    email: '',
    newsletter: false,
    isEmailInvalid: false,
    error: ''
  };

  const [submission, setSubmission] = React.useState<PardotFormState>(initialValues);
  const [loading, setLoading] = React.useState(true);

  // disable/enable download button dynamically
  React.useEffect(() => {
    if (submission.email !== '' && !submission.isEmailInvalid && submission.newsletter) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [submission.email, submission.newsletter]);

  const checkEmailInvalid = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '' || !re.test(email)) {
      setSubmission({ ...submission, error: emailDialogContent ? emailDialogContent.ErrorLabel : 'Error submitting form' });
      return true;
    }
    setSubmission({ ...submission, error: '' });
    return false;
  };

  const handleSubmissionChange = (prop: keyof PardotFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const verifyEmail = prop === 'email' ? checkEmailInvalid(event.target.value) : submission.isEmailInvalid;
    setSubmission({ ...submission, [prop]: value, isEmailInvalid: verifyEmail });
  };

  // Submit the email data to Salesforce Pardot Form Handler
  const handleSubmission = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const value = `email=${submission.email}`;
    if (!submission.isEmailInvalid && submission.newsletter) {
      setLoading(true);

      submitPardotForm(`${GATSBY_PARDOT_ENDPOINT_URL}?${value}`)
        .then(() => {
          setSubmission(initialValues); // clear download state
          onDialogClose(true, submission.email);
        })
        .catch(() => {
          setSubmission({ ...submission, error: emailDialogContent ? emailDialogContent.ErrorLabel : 'Error submitting form' });
        });
    } else {
      setSubmission({ ...submission, error: emailDialogContent ? emailDialogContent.ErrorLabel : 'Error submitting form' });
    }
  };

  const handleDialogClose = () => {
    onDialogClose();
  };

  const matchesMobile = useMediaQuery('(max-width: 600px)');

  if (emailDialogContent) {
    return (
      <Dialog
        fullScreen={!!matchesMobile}
        maxWidth='sm'
        onClose={handleDialogClose}
        open={open}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        <div className={classes.dialogClose}>
          <IconButton aria-label='close' onClick={handleDialogClose} className='close-icon-button'>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent style={{ padding: '0px 24px' }}>
          <div className={classes.dialogTitle}>
            <Typography variant='h6'>{templateTitle}</Typography>
          </div>
          <form onSubmit={handleSubmission}>
            <div className={classes.dialogFormContainer}>
              <FormControl variant='outlined' size='medium'>
                <InputLabel htmlFor='outlined-email'>{emailDialogContent.EmailLabel}</InputLabel>
                <OutlinedInput
                  id='outlined-email'
                  type='email'
                  name='email' // name map to the field name set in Pardot form handler
                  error={submission.isEmailInvalid}
                  value={submission.email}
                  onChange={handleSubmissionChange('email')}
                  onBlur={() => checkEmailInvalid(submission.email)}
                  labelWidth={40}
                />
              </FormControl>
              <FormControl variant='outlined' size='medium' className={classes.formControl}>
                <FormControlLabel
                  control={<Checkbox checked={submission.newsletter} onChange={handleSubmissionChange('newsletter')} />}
                  label={<ReactMarkdown children={emailDialogContent.NewsletterLabel} className={classes.markdownText} />}
                />
              </FormControl>
              {submission.error !== '' ? (
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                  <Typography variant='caption' color='error' align='center'>
                    {submission.error}
                  </Typography>
                </div>
              ) : null}
              <Button
                disabled={loading}
                size='large'
                fullWidth
                variant='contained'
                type='submit'
                color='secondary'
                classes={{ disabled: classes.disabledButton, contained: classes.containedButtn }}
              >
                {emailDialogContent.DownloadLabel}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
  return null;
};

export default PardotEmailDialog;
