import { Template } from '../typings';

export async function exportToPDF(pdfEndpoint: string, template: Template) {
  const response = await fetch(`${pdfEndpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(template)
  })
    .then((resp) => resp.arrayBuffer())
    .then((resp) => {
      // set the blog type to final pdf
      const fileType = 'application/pdf';
      const fileName = `Template ${template.name}.pdf`;
      const file = new Blob([resp], { type: fileType });
      // process to auto download it
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;
      link.click();

      const fileData = {
        fileContent: resp,
        fileName,
        fileType
      };
      return fileData;
    });

  return response;
}
