import { gql, useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { NovadeLiteUrlContext } from '../contexts/NovadeLiteUrlContext';
import { DatabasesMap, NovadeLiteUrlContextType, NovadeLiteTemplateInput } from '../typings';
import { setGTMEvent } from '../utils/setGAEvent';

interface WorkspaceDialogProps {
  open: boolean;
  template: NovadeLiteTemplateInput;
  onDialogClose: (isSuccessfulSave?: any) => void;
  selectLabel: string;
  btnLabel: string;
  workspaces: DatabasesMap;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogTitleContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0.5rem 0.5rem 0 0'
    },
    dialogContainer: {
      padding: '0rem 4rem 4rem 4rem !important'
    },
    dialogLogoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '2rem'
    },
    dialogFormContainer: {
      display: 'grid',
      gap: '1rem',
      marginBottom: '1rem'
    }
  })
);

const WorkspaceDialog: React.FC<WorkspaceDialogProps> = ({ open, template, onDialogClose, selectLabel, btnLabel, workspaces }) => {
  const classes = useStyles();
  const [workspaceID, setWorkspaceID] = React.useState('');

  const contextUrl = React.useContext<NovadeLiteUrlContextType | null>(NovadeLiteUrlContext);
  const token = workspaces[workspaceID]?.token ?? '';

  const handleWorkspaceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setWorkspaceID(event.target.value as string);
  };
  const handleClose = () => {
    onDialogClose();
  };

  const UPSERT_TEMPLATE_DATE = gql`
    mutation upsertTemplateData($template: TemplatePortalInput!) {
      savePortalTemplateToWorkspace(template: $template)
    }
  `;
  const [upsertTemplateData, { loading }] = useMutation<{ savePortalTemplateToWorkspace: any }, { template: NovadeLiteTemplateInput }>(
    UPSERT_TEMPLATE_DATE,
    {
      variables: {
        template
      },
      context: {
        uri: `${contextUrl?.novadeLiteUrl}/graphql`,
        headers: token ? { Authorization: `Bearer ${token}` } : undefined
      },
      onCompleted({ savePortalTemplateToWorkspace }) {
        if (savePortalTemplateToWorkspace) {
          setGTMEvent('Save template to Novade Lite workspace', 'save_To_Novade_Lite', template.name);
          onDialogClose(true);
        }
      },
      onError({ graphQLErrors, networkError }) {
        if (graphQLErrors || networkError) {
          onDialogClose(false);
        }
      }
    }
  );

  const workspaceEntries = Object.entries(workspaces);
  if (workspaceEntries.length > 0) {
    return (
      <Dialog maxWidth='xs' fullWidth onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
        <div className={classes.dialogTitleContainer}>
          <IconButton aria-label='close' onClick={handleClose} className='close-icon-button'>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContainer}>
          <div className={classes.dialogLogoContainer}>
            <StaticImage
              layout='constrained'
              placeholder='tracedSVG'
              src='../assets/LoginDialog/novade-lite-logo.svg'
              alt='Novade Lite logo'
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <StaticImage
              layout='constrained'
              placeholder='tracedSVG'
              src='../assets/LoginDialog/novade-lite-logo-text.svg'
              alt='Novade Lite Text logo'
            />
          </div>
          <div className={classes.dialogFormContainer}>
            <FormControl fullWidth variant='outlined' size='medium'>
              <InputLabel style={{ zIndex: 0 }} id='workspace-select-outlined-label'>
                {selectLabel}
              </InputLabel>
              <Select label={selectLabel} labelId='workspace-select-outlined-label' value={workspaceID} onChange={handleWorkspaceChange}>
                {workspaceEntries.map(([databaseID, workspace]) => (
                  <MenuItem value={databaseID} key={databaseID}>
                    {workspace.databaseName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button
            disabled={!workspaceID || loading}
            size='large'
            fullWidth
            variant='contained'
            color='secondary'
            style={{ textTransform: 'uppercase', color: 'white' }}
            onClick={() => upsertTemplateData()}
          >
            {!loading ? (
              btnLabel
            ) : (
              <div>
                <CircularProgress style={{ height: '1rem', width: '1rem', color: 'white' }} />
              </div>
            )}
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
};

export default WorkspaceDialog;
