import { MarkerType } from '@xyflow/react';
import { sortBy } from 'lodash';
import { WorkflowStep, WorkflowAction, StrapiTemplatePageQueryProps, NovadeLiteWorkflowStepInput } from '../typings';
import { convertARGBNumberToRGBHexString, getFontColor } from './common';

export enum WorkflowPhase {
  Preparation = 'Preparation',
  Action = 'Action',
  Closing = 'Closing'
}

export function setWorkflowPhase(phase: WorkflowStep['phase']): WorkflowPhase {
  switch (phase) {
    case 0:
      return WorkflowPhase.Preparation;
    case 1:
      return WorkflowPhase.Action;
    case 2:
      return WorkflowPhase.Closing;
    default:
      return WorkflowPhase.Preparation;
  }
}

type Step = {
  id: string;
  name: string;
  color: string;
  phase: string;
  x: number;
  y: number;
};

type WorkflowStepMap = {
  [key in WorkflowStep['id']]: Step;
};

interface PhaseDetails {
  label: string;
  tooltip: string;
}

type LocalizedPhasesMap = {
  [key in WorkflowPhase]: PhaseDetails;
};

type PositionYByPhaseMap = {
  [key in WorkflowPhase]: number;
};

type PhasePositionYAndHeightMap = {
  [key in WorkflowPhase]: { height: number; positionY: number; positionX: number };
};

type NodeEdgeData = {
  [key in string]: any;
};

type NodePosition = {
  x: number;
  y: number;
};

type Node = {
  id: string;
  type: string;
  data: NodeEdgeData;
  position: NodePosition;
  height?: number;
  parentId?: string;
  extent?: string;
  className?: string;
  style?: { [key in string]: string | number };
  draggable?: boolean;
  selectable?: boolean;
};

type Edge = {
  id: string;
  source: Node['id'];
  target: Node['id'];
  data: NodeEdgeData;
  style?: { [key in string]: string | number };
  type: string;
  labelStyle?: { [key in string]: string | number };
  markerEnd?: { type: string };
};

export type WorkflowMap = {
  nodes: Node[];
  edges: Edge[];
};

const workflowPhases = [WorkflowPhase.Preparation, WorkflowPhase.Action, WorkflowPhase.Closing];

const defaultColor = '#5e5e69';

const initialPhaseNodeStyle = {
  draggable: false,
  selectable: false,
  style: {
    width: 'inherit',
    height: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    borderColor: '#E0E0E0'
  }
};

const initialEdgeStyle = {
  type: 'action',
  markerEnd: {
    type: MarkerType.ArrowClosed
  },
  labelStyle: { fontSize: '0.6rem' }
};

function getAnnotationNode(phase: WorkflowPhase, localizedPhasesMap: LocalizedPhasesMap) {
  return {
    id: `annotation-${phase}`,
    type: 'annotation',
    draggable: false,
    data: {
      label: localizedPhasesMap[phase].label,
      tooltip: localizedPhasesMap[phase].tooltip
    },
    parentId: phase,
    extent: 'parent',
    position: { x: 0, y: 0 }
  };
}

/**
 * @function getLocalizedPhasesMap
 * @description Generates a map of workflow phases with localized labels and tooltips.
 */
const getLocalizedPhasesMap = (templatePageContent: StrapiTemplatePageQueryProps): LocalizedPhasesMap => ({
  [WorkflowPhase.Preparation]: { label: templatePageContent.Preparation, tooltip: templatePageContent.PreparationTooltip },
  [WorkflowPhase.Action]: { label: templatePageContent.Action, tooltip: templatePageContent.ActionTooltip },
  [WorkflowPhase.Closing]: { label: templatePageContent.Closing, tooltip: templatePageContent.ClosingTooltip }
});

/**
 * @function getPositionYByPhaseMap
 * @description Gets a map of y position by phase map.
 */
const getPositionYByPhaseMap = (workflowSteps: NovadeLiteWorkflowStepInput[]): PositionYByPhaseMap =>
  workflowSteps.reduce((map: any, { phase, y }) => {
    let positionY = phase in map ? map[phase] : y;
    if (y < positionY) positionY = y;
    return { ...map, [phase]: positionY };
  }, {});

/**
 * @function getPhasePositionAndHeightMap
 * @description Generates a map containing the X and Y positions and height for each phase.
 * The height is calculated based on the difference in Y positions between consecutive phases,
 * with a default height assigned to the last phase or if the next phase Y position is undefined.
 */
const getPhasePositionAndHeightMap = (positionYByPhaseMap: PositionYByPhaseMap): PhasePositionYAndHeightMap => {
  const defaultHeight = 300;
  return workflowPhases.reduce((acc, phase, index) => {
    const positionY = positionYByPhaseMap[phase];
    const positionX = 0;
    if (index === workflowPhases.length - 1) {
      acc[phase] = { positionX, positionY, height: defaultHeight };
    } else {
      const nextPhase = workflowPhases[index + 1];
      const nextPhaseY = positionYByPhaseMap[nextPhase];
      const height = nextPhaseY !== undefined ? nextPhaseY - positionY : defaultHeight;
      acc[phase] = { positionX, positionY, height };
    }
    return acc;
  }, {} as PhasePositionYAndHeightMap);
};

/**
 * @function createStepNode
 * @description Returns the object of step
 */
const createStepNode = (step: NovadeLiteWorkflowStepInput, phaseNode: Node, rgbColor: string): Node => {
  const gap = 20;
  const stepX = step.x < 0 ? 0 : step.x;
  return {
    id: step.id,
    type: 'tools',
    data: { label: step.name, color: rgbColor },
    position: { x: stepX + gap, y: step.y - phaseNode.position.y + gap },
    parentId: phaseNode.id,
    extent: 'parent'
  };
};

/**
 * @function createEdge
 * @description Returns the object of edge
 */
const createEdge = (action: WorkflowAction, workflowStepsMap: WorkflowStepMap, templatePageContent: StrapiTemplatePageQueryProps): Edge => {
  const { fromStepID, toStepID } = action;
  let actionName = '';
  let stepColor = defaultColor;
  if (workflowStepsMap[toStepID]) {
    actionName = templatePageContent.ActionPrefix.replace('XXX', workflowStepsMap[toStepID].name);
    stepColor = workflowStepsMap[toStepID].color;
  }
  const fontColor = getFontColor(stepColor);
  return {
    id: `${fromStepID}|${toStepID}`,
    source: fromStepID,
    target: toStepID,
    data: { label: actionName, color: fontColor, bgColor: stepColor },
    style: { strokeWidth: 2, stroke: stepColor },
    ...initialEdgeStyle
  };
};

/**
 * @function generateWorkflowChart
 * @description Generates a workflow chart by creating nodes and edges based on workflow steps, actions.
 */
export function generateWorkflowChart(
  workflowSteps: NovadeLiteWorkflowStepInput[],
  workflowActions: WorkflowAction[],
  templatePageContent: StrapiTemplatePageQueryProps
): WorkflowMap {
  const phaseNodesMap: { [key in Node['id']]: Node } = {};
  const stepNodesMap: { [key in Node['id']]: Node } = {};
  const annotationNodes: Node[] = [];
  const edges: Edge[] = [];
  const workflowStepsMap: WorkflowStepMap = {};
  const sourceNodeIDsSet = new Set();
  const targetNodeIDsSet = new Set();
  const localizedPhasesMap = getLocalizedPhasesMap(templatePageContent);

  const sortedWorkflowSteps = sortBy(workflowSteps, ['phase', 'y']);
  // Calculate the position Y of each phase
  const positionYByPhaseMap = getPositionYByPhaseMap(workflowSteps);

  // Calculate height of each phase
  const phasePositionYAndHeightMap = getPhasePositionAndHeightMap(positionYByPhaseMap);

  for (const step of sortedWorkflowSteps) {
    const { color, phase } = step;
    const rgbColor = convertARGBNumberToRGBHexString(color);
    const { positionY: phasePositionY, positionX: phasePositionX, height: phaseHeight } = phasePositionYAndHeightMap[phase];

    // create phase node and corresponding annotation node
    if (!phaseNodesMap[phase]) {
      phaseNodesMap[phase] = {
        id: phase,
        type: 'group',
        data: { label: phase, color: rgbColor },
        position: { x: phasePositionX, y: phasePositionY },
        height: phaseHeight,
        ...initialPhaseNodeStyle
      };

      // Create the corresponding annotation node
      const annotationNode = getAnnotationNode(phase as WorkflowPhase, localizedPhasesMap);
      annotationNodes.push(annotationNode);
    }

    // create step node
    stepNodesMap[step.id] = createStepNode(step, phaseNodesMap[phase], rgbColor);
    workflowStepsMap[step.id] = { ...step, color: rgbColor };
  }

  // create edges
  for (const action of workflowActions) {
    const { fromStepID, toStepID } = action;
    const edge = createEdge(action, workflowStepsMap, templatePageContent);
    edges.push(edge);
    sourceNodeIDsSet.add(fromStepID);
    targetNodeIDsSet.add(toStepID);
  }

  const stepNodes = Object.values(stepNodesMap).map((node) => {
    const { id, data } = node;
    data.hasOutgoingLink = sourceNodeIDsSet.has(id);
    data.hasIncomingLink = targetNodeIDsSet.has(id);
    return node;
  });

  const nodes = [...Object.values(phaseNodesMap), ...annotationNodes, ...stepNodes];

  return { nodes, edges };
}
