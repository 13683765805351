import * as React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { SectionField, TemplateSection, ScoreByRankMap, TemplateSectionContainerProps, TemplateScoreContextType } from '../typings';
import SectionFieldContainer from './SectionFieldContainer';
import useTemplateSectionStyle from '../styles/TemplateSectionContainer';
import { TemplateScoreContext } from '../contexts/TemplateScoreContext';
import { getScoreDisplayValues } from '../utils/previewScoreUtils';
import Score from './SectionFields/Score';

function renderTemplateSectionTitle(isMobile: boolean, templateSection: TemplateSection, templateMaxScoreMap: ScoreByRankMap) {
  const { name, rank } = templateSection;
  const templateScore = React.useContext<TemplateScoreContextType>(TemplateScoreContext);
  const sectionScoreMap = templateScore.templateScoreMap[rank] ?? {};
  let displayedScoreStr = '';
  if (templateMaxScoreMap) {
    const maxScores = templateMaxScoreMap[rank] ?? 0;
    const sectionScoreEnabled = templateMaxScoreMap[rank] !== undefined;
    displayedScoreStr = sectionScoreEnabled ? getScoreDisplayValues(sectionScoreMap, maxScores) : '';
  }
  const showDivider = displayedScoreStr || !isMobile;
  const classes = useTemplateSectionStyle();
  const className = isMobile ? classes.templateSectionTitleMobile : classes.templateSectionTitleWeb;
  return (
    <>
      {showDivider ? <Divider aria-hidden='true' style={{ margin: '1rem 0' }} /> : null}
      <Typography variant='h2' className={className} key={name}>
        {name}
      </Typography>
      <Score label='Score' scoreValue={displayedScoreStr} isMobile={isMobile} />
    </>
  );
}

const TemplateSectionContainer: React.FC<TemplateSectionContainerProps> = ({
  isMobile,
  templateSection,
  templateLanguage,
  templateMaxScoreMap
}) => (
  <div>
    {renderTemplateSectionTitle(isMobile, templateSection, templateMaxScoreMap)}
    {templateSection.sectionFields.map((eachSectionField: SectionField) => (
      <div key={`sectionField_${eachSectionField.rank}`}>
        <SectionFieldContainer
          isMobile={isMobile}
          sectionFieldDetails={eachSectionField}
          sectionRank={templateSection.rank}
          templateLanguage={templateLanguage}
        />
      </div>
    ))}
  </div>
);

export default TemplateSectionContainer;
