import React from 'react';
import { Typography } from '@material-ui/core';
import { TemplateInfoSectionProps } from 'typings';
import useTemplateStyles from '../../styles/templateStyle';
import TemplateTags from './TemplateTags';

const TemplateInfoSection: React.FC<TemplateInfoSectionProps> = (templateInfoProps) => {
  const { isMobile, templateTitle, authorLabel, author, description, publishedDateLabel, publishedDate, tagsLabel, tags } =
    templateInfoProps;
  const classes = useTemplateStyles();
  const alignment = isMobile ? 'center' : 'left';

  return (
    <div className={classes.templateInfoSection}>
      <Typography variant='h1' align={alignment} className={`${classes.marginBottom} ${classes.templateHeaderOne}`}>
        {templateTitle}
      </Typography>
      <Typography variant='body1' align={alignment} color='primary' className={`${classes.marginBottom} ${classes.templateBodyText}`}>
        {authorLabel} {author}
      </Typography>
      <Typography variant='body1' align={alignment} className={`${classes.marginBottom} ${classes.templateBodyText}`}>
        {description}
      </Typography>
      {tags && tags.length ? (
        <Typography variant='body1' align={alignment} className={`${classes.marginBottom} ${classes.templateBodyText}`}>
          {tagsLabel} <TemplateTags tags={tags} />
        </Typography>
      ) : null}
      <Typography variant='caption'>
        {publishedDateLabel} {new Date(publishedDate).toLocaleDateString()}
      </Typography>
    </div>
  );
};

export default TemplateInfoSection;
