/* eslint-disable prettier/prettier */
import * as React from 'react';
import { TemplateScoreMap, TemplateScoreContextType, TemplateSection, SectionField } from '../typings';

export const TemplateScoreContext = React.createContext<TemplateScoreContextType>({
  templateScoreMap: {},
  updateTemplateScoreMap: () => { }
});

const TemplateScoreProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [templateScoreMap, setTemplateScoreMap] = React.useState<TemplateScoreMap>({});

  const updateTemplateScoreMap = (
    sectionRank: TemplateSection['rank'],
    rank: SectionField['rank'],
    score: number,
    usedForScore: boolean
  ) => {
    if (usedForScore) {
      const sectionScoreMap = templateScoreMap[sectionRank] ?? {};
      sectionScoreMap[rank] = score;
      setTemplateScoreMap({ ...templateScoreMap, [sectionRank]: sectionScoreMap });
    }
  };

  const scoreContext = { templateScoreMap, updateTemplateScoreMap };
  return <TemplateScoreContext.Provider value={scoreContext}>{children}</TemplateScoreContext.Provider>;
};

export default TemplateScoreProvider;
