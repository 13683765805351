import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { DatabasesMap } from 'typings';

const useWorkspacesWithRights = (endpoint: string, token: string, databasesMap: DatabasesMap) => {
  const [workspacesMap, setWorkspacesMap] = React.useState<DatabasesMap>({});

  const GET_WORKSPACES_WITH_RIGHTS = gql`
    query getWorkspacesWithRights($userRights: UsersWithRightsInput!) {
      workspacesWithRights(userRights: $userRights) {
        id
        name
      }
    }
  `;

  const { loading, error, refetch } = useQuery(GET_WORKSPACES_WITH_RIGHTS, {
    variables: { userRights: { manageTemplate: true } },
    context: {
      uri: `${endpoint}/graphql`,
      skip: !token && !endpoint, // Skip the query if token or endpoint is not available
      headers: token ? { Authorization: `Bearer ${token}` } : undefined
    },
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const workspacesWithRightsMap: DatabasesMap = {};
      const workspaceIDs = data.workspacesWithRights.map((workspace: any) => workspace.id);
      Object.keys(databasesMap).forEach((databaseID) => {
        if (workspaceIDs.includes(databaseID)) workspacesWithRightsMap[databaseID] = databasesMap[databaseID];
      });
      setWorkspacesMap(workspacesWithRightsMap);
    },
    onError: (e) => {
      console.error('error', e);
    }
  });

  // Re-fetch workspace data when endpoint or token changes
  React.useEffect(() => {
    refetch();
  }, [token]);

  return { loading, error, workspacesMap };
};

export default useWorkspacesWithRights;
