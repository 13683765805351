import { createStyles, makeStyles } from '@material-ui/core/styles';

const useTemplateStyles = makeStyles(() =>
  createStyles({
    templatePreview: {
      margin: '0 10rem 0 5rem'
    },
    closeButton: {
      backgroundColor: '#fff',
      width: '1.5em',
      height: '1.5em',
      '&:hover': {
        backgroundColor: '#f4f4f4'
      }
    },
    dialogTitle: {
      padding: '1em 0',
      color: '#fff',
      '& p, h1': {
        paddingBottom: '0.5em'
      },
      '& h1': {
        fontWeight: '600'
      }
    },
    descriptionContainer: {
      display: '-webkit-box',
      boxOrient: 'vertical',
      overflow: 'hidden'
    },
    dialogContainer: {
      padding: '1rem 0 !important',
      color: '#fff'
    },
    previewGrid: {
      padding: '0 3em 0 0'
    },
    previewCard: {
      /*  overflow: "unset", */
      backgroundColor: 'transparent'
    },
    previewHeader: {
      padding: '0.5rem 1rem',
      backgroundColor: '#f4f4f4',
      display: 'grid',
      gridTemplateColumns: '18px 1fr',
      gap: '1rem',
      alignItems: 'center',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      '&:p': {
        fontSize: '1rem'
      }
    },
    buttonList: {
      flexDirection: 'column',
      '&:hover': {
        backgroundColor: 'unset'
      }
    },
    buttonCircle: {
      padding: '0.5rem',
      background: '#fff',
      borderRadius: '50%',
      width: '1.5rem',
      height: '1.5rem',
      letterSpacing: '-1px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    listItemText: {
      textAlign: 'center',
      padding: '0.5rem 0',
      color: '#2864ac',
      fontSize: '0.7rem'
    },
    mobileForm: {
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px'
    },
    dialogPaper: {
      top: '10px',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        width: '0px',
        background: 'transparent'
      }
    },
    // Template SEO section
    templateSeoSection: {
      margin: '0.5em 0'
    },
    templateSeoText: {
      padding: '1em 0'
    },
    // Template Page
    templateHeaderOne: {
      fontSize: '1.25rem',
      fontWeight: 600
    },
    templateHeaderTwo: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.43
    },
    templateBodyText: {
      fontSize: '0.8rem'
    },
    templateInfoSection: {
      margin: '0.5rem 0'
    },
    marginBottom: {
      marginBottom: '0.5rem'
    },
    flexSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    secondaryButton: {
      color: 'white'
    },
    mobileFooter: {
      bottom: 0,
      top: 'auto',
      backgroundColor: '#f8f8f8'
    },
    mobileFooterToolbar: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '1rem'
    },
    actionButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem'
    }
  })
);

export default useTemplateStyles;
