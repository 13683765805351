export enum FieldInputType {
  ShortText = 'ShortText',
  LongText = 'LongText',
  NumberInteger = 'NumberInteger',
  NumberDecimal = 'NumberDecimal',
  SingleSelect = 'SingleSelect',
  MultiSelect = 'MultiSelect',
  DateAndTime = 'DateAndTime',
  Date = 'Date',
  Time = 'Time',
  Toggle = 'Toggle',
  Photo = 'Photo',
  Signature = 'Signature',
  Checkbox = 'Checkbox',
  Label = 'Label',
  Table = 'Table',
  Formula = 'Formula'
}
