import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';

interface EmptyColumnProps {
  isEmpty: boolean;
}

const EmptyColumn: React.FC<EmptyColumnProps> = ({ isEmpty }) => {
  if (isEmpty) return <TableCell style={{ width: '100%' }} />;
  return null;
};

export default EmptyColumn;
